.new-budget {
  .budget-container {
    background-color: #fff;
    margin-bottom: 2rem;
  }

  .budget-section {
    border: 1px solid #ccc;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #f9f9f9;
    margin-top: 2.25rem;

    &.final {
      border: none !important;
      background-color: transparent !important;
      margin-top: 0;
    }
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;

    .header-info {
      display: flex;
      justify-content: space-between;
      flex: 1;

      .info {
        flex: 1;
        text-align: center;
      }
    }

    .category-capacity {
      display: inline-flex;
      align-items: center;
      gap: 5px;
    }

    &.final {
      display: flex;
      flex-direction: column;
      padding: 0;
      padding-bottom: 1rem;
      width: 20%;
    }
  }

  .review-info {
    display: flex;
    justify-self: center;
    align-items: center;
    width: 90%;
    margin-bottom: 2rem;
    gap: 2rem;

    .info-section {
      padding-right: 1rem;

      border-right: 1px solid #949494;

      &:last-of-type {
        border: none;
      }
    }
  }

  .section-split-line {
    width: 75%;
    margin: 0 auto;

    border: 1px solid #949494;
    display: block;
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .total-lines {
    height: 6px;
    border-top: 1px solid #949494;
    border-bottom: 1px solid #949494;
    margin-bottom: 1rem;
  }

  .budget-table-wrapper {
    text-align: center;
    &.final {
      border-bottom: 1px solid #ccc;
    }
  }

  .budget-table {
    border-collapse: collapse;
    min-width: 1800px;
  }

  .budget-item-form {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    align-items: center;
  }

  .empty-message {
    text-align: center;
    font-style: italic;
    color: #666;
  }

  .budget-item-form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    background-color: #f9f9f9;
  }

  .form-group {
    flex: 1;
    min-width: 180px;
  }

  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .form-actions {
    display: flex;
    gap: 8px;
    margin-top: 10px;
  }

  .fixed-table,
  .budget-table {
    margin-bottom: 0;
  }

  .final-table,
  thead,
  tbody,
  tfoot,
  td,
  th {
    border-style: none !important;
  }

  .final-table {
    thead {
      tr {
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .section-totals-container,
  .grand-total-container {
    width: 25%;
    text-align: right;
    margin-top: 0.5rem;
    margin-left: auto;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;

      &.underline {
        border-bottom: 1px solid #ccc;
      }

      .label {
        text-align: left;
        flex: 1;
        font-weight: bold;
      }

      .value {
        text-align: right;
        flex: 1;
      }

      .over-capacity {
        font-weight: bold;
        color: red;
      }
    }
  }

  .grand-total-container {
    margin-top: 2rem;
  }
}
