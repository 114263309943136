.tab-container {
  .nav-item {
    cursor: pointer;
    .nav-link {
      color: black;
      height: auto;
      padding-bottom: 5px;
      &.active {
        border-top: 2px solid #a6caff !important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
}
