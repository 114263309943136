.dashboard {
  .dashboard-modal {
    border: none;
    margin-bottom: 1rem;
  }
}

@import 'alerts';
@import 'care-worker-map';
@import 'notifications';
@import 'prospect-statistics';
@import 'snapshots';
@import 'upcoming-appointments';
@import 'week-schedule';
