.weekly-planner {
  height: 100%;
  position: absolute;
  inset: 0;
  .banner {
    .settings-icon {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: top;
    }
    .published-state {
      text-align: center;
      display: flex;
      align-items: center;
      border-radius: 10rem;
      padding: 0rem 1rem;
      font-weight: bold;
      background-color: $danger;
      color: white;

      &.published {
        background-color: $success;
      }
      &.partial {
        background-color: $danger;
        background-image: -webkit-linear-gradient(45deg, $success 50%, $danger 50%);
      }
    }
  }

  .main-view {
    position: relative;
    height: calc(100% - 100px);
    flex-wrap: nowrap !important;
    --bs-gutter-x: 0;
  }

  .weekly-planner-context {
    .dropdown-menu {
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    }

    .dropdown-item {
      font-size: small;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .hover-icon {
        opacity: 0;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-duration: 500ms;
      }

      &:hover {
        background-color: #e3e3e3;
        .hover-icon {
          opacity: 1;
          transition: cubic-bezier(0.075, 0.82, 0.165, 1);
          transition-duration: 500ms;
        }
      }

      &:active {
        background-color: theme-color('menu-background');
        color: theme-color('menu-foreground');
        opacity: 0.6;
      }
    }
    .dropdown-header {
      font-size: smaller;
    }
  }
}

.date-chevron {
  width: 24px;
  background-color: transparent;
  border: none;
  color: black;

  &:hover {
    color: black;
    cursor: pointer;
    border: none;
    background-color: theme-color('week-picker-hover');
  }
}

.worker-cell,
.unallocated-card,
.appointment-modal,
.appointment-popover,
.wizard-form,
[class*=' worker-card'] {
  .notes {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .sms-icon:hover {
    cursor: pointer;
  }
}

.worker-cell {
  .award-alert {
    align-items: center;
    justify-content: end;
    text-align: end;
    border: solid $danger 0.25rem;
    margin: 0.25rem;

    .alert-icon {
      color: $warning;
    }
  }
}

.worker-card {
  min-width: 1140px !important;
}

@import 'sidebar/index';
@import 'appointment-card';
@import 'appointment-popover';
@import 'bidding-card';
@import 'daily-view';
@import 'focus-bar';
@import 'legend';
@import 'qualification-icons';
@import 'service-colour';
@import 'unallocated-card';
@import 'weekly-view';
