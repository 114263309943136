.body-wrapper {
  height: auto;

  .week-schedule {
    min-width: 1400px !important;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .main-view {
      height: auto !important;
      padding-bottom: 1rem;
    }

    .weekly-view-wrapper {
      display: grid !important;
      grid-template-rows: auto 1fr !important;
      height: 100%;

      .weekly-view-container {
        scrollbar-gutter: unset !important;
      }

      .header-wrapper {
        grid-column: 1 / -1;
        grid-row: 1;
        display: grid;
        grid-template-columns: 200px repeat(7, minmax(5%, 1fr));
        gap: 1px;
        background-color: #fff;
        overflow-y: hidden;
        scrollbar-gutter: unset !important;

        .header-cell {
          align-self: start;
          padding: 10px;
          background-color: theme-color('header-end');
          text-align: center;
          position: relative;
          height: 60px;
          max-height: 60px;
          overflow: hidden;

          &.today {
            background-color: theme-color('header-start');
            font-weight: bold;
          }
        }
      }

      .week-header,
      .worker-row {
        display: grid;
        grid-column: 1 / -1;
        grid-template-columns: 200px repeat(7, minmax(5%, 1fr));
        height: 100% !important;
      }
    }

    .center-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}
