.file-area {
  position: relative;
  border: 2px dashed theme-color('file-upload-border');
  text-align: center;
  font-size: $font-size-base;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 1rem;
  height: 100%;
  border-radius: 6px;
  flex-direction: column;
  padding: 20px;

  &.over {
    background-color: lighten(theme-color('menu-background'), 40%);
    border-color: theme-color('menu-background');
  }

  .input {
    display: none;
  }

  label {
    display: block;
    cursor: pointer;
    color: theme-color('file-upload-text');
    margin-bottom: 10px;
  }

  .dragndrop {
    color: theme-color('menu-background');
  }

  .button {
    background-color: theme-color('menu-background');
    color: $white;
    padding: 12px 25px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    transition:
      background-color 0.3s ease,
      transform 0.2s ease;
    display: block;

    &:hover {
      background-color: theme-color('hovered-background');
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
    }

    &:focus {
      outline: 2px solid theme-color('menu-background');
      outline-offset: 2px;
    }
  }

  .error-message {
    color: theme-color('red');
    margin-top: 15px;
  }
}

.preview {
  height: 100%;
  display: grid;
  grid-template-rows: 40px 1fr;
  padding: 0px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: theme-color('file-upload-background');
    border-bottom: 2px solid theme-color('table-border-color');
    padding: 1.25rem;

    .name {
      color: theme-color('file-upload-text');
      font-weight: bold;
    }
  }

  .content {
    grid-row: 2;
    padding: 0px;
    max-width: 100%;
    .image {
      max-height: 100%;
      max-width: 100%;
      width: 100%;
    }

    .pdf {
      width: 100%;
      height: 100%;
    }
  }

  .preview img {
    max-width: 100%;
    height: auto;
    border-radius: 6px;
  }
}

.file-upload-modal {
  width: 35vw !important;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}
