.weekly-view-wrapper {
  height: 100%;
  display: grid;

  &:not(.focused-row) {
    grid-template-rows: 60px auto;
  }

  .header-wrapper {
    display: grid;
    grid-template-columns: 200px repeat(7, minmax(5%, 1fr));
    scrollbar-gutter: stable;
    overflow: auto;
    gap: 1px;
    background-color: #fff;

    .header-cell {
      align-self: start;
      padding: 10px;
      background-color: theme-color('header-end') !important;
      text-align: center;
      position: sticky;
      top: 0;
      z-index: 10;
      height: 60px;
      max-height: 60px;
      overflow: hidden;

      &.today {
        background-color: theme-color('header-start');
        font-weight: bold;
      }

      .clickable {
        cursor: pointer;
        color: inherit;
        padding: 1rem;

        &:hover {
          font-weight: bold;
          color: $red;
        }
      }
    }
  }

  .infinite-scroll-component__outerdiv {
    height: 100%;
    overflow-y: auto;
  }

  .draggable-container {
    height: 100%;
    width: 100%;
  }

  .focus-row {
    padding-bottom: 2px;

    .worker-cell,
    .day-cell {
      border-top: 3px solid $orange;
      border-bottom: 3px solid $orange;
    }
  }

  .empty-results {
    text-align: center;
    padding: 5rem;
    height: 15%;
  }

  .weekly-view-container {
    height: 100%;
  }

  .weekly-view-container,
  .focus-row {
    display: grid;
    grid-template-columns: 200px repeat(7, minmax(5%, 1fr));
    gap: 0.1%;
    row-gap: 0.25%;
    overflow-y: auto;
    scrollbar-gutter: stable;
    background-color: $weekly-planner-background-colour;
    position: relative;

    &.initial-load {
      overflow-y: hidden;
    }

    .spinner {
      grid-column: 1 / span 8;
    }

    .header-wrapper {
      display: contents;
    }

    .worker-row {
      display: contents;

      .worker-cell-parent {
        background-color: $weekly-planner-background-colour;
      }

      > div {
        &:first-child {
          margin-left: 2px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }

      .spinner {
        align-items: center;
        z-index: 2;
        opacity: 0.6;
        background-color: $weekly-planner-background-colour;
      }
    }

    [class*='-cell'] {
      background-color: $white;
      position: relative;
      min-width: 75px;
    }

    .worker-cell {
      display: flex;
      justify-content: space-between;
      position: relative;
      grid-column: 1;
      height: 100%;

      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-left: solid 1px #e0e0e0;

      .tooltips {
        height: 100%;
        margin-bottom: 5px;
        text-align: center;
        justify-content: space-evenly;
      }
    }

    .day-cell {
      transition: background-color 100ms ease-in-out;

      .availability {
        text-align: center;
        margin-bottom: 5px;
        font-size: 8pt;
        color: #333;
        padding: 0.25rem 0;
        border-bottom: 2px solid $weekly-planner-background-colour;
        &:hover .availability-split {
          text-decoration: underline;
          cursor: pointer;
        }

        .unavailable {
          color: $red;
        }
        .availability-split {
          font-weight: bold;
        }
      }

      &:not(.available) {
        background-color: #eee;
      }

      .event-rows {
        min-height: 100px;
        padding: 0.25rem 0.25rem;

        [class*='-row'] {
          margin-top: 0.25rem;
          margin-bottom: 0.25rem;
        }

        [class*='-card'] {
          user-select: none;
          touch-action: none;

          div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .appointment-row {
          &.conflict {
            border: 4px solid $warning;
            background-color: $warning;
            display: grid;
            grid-gap: 0.25rem;
          }
        }

        .travel-row {
          margin-top: -0.25rem;

          &.conflict {
            border: 2px;
            border-color: $warning;
            border-style: none dashed dashed dashed;
          }
        }

        .travel-card {
          padding: 0.25rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .icons {
            display: flex;
            min-width: 30px;
            justify-content: space-between;
            flex-direction: row-reverse;
          }

          &.start {
            .icons {
              flex-direction: row;
            }
          }
        }
      }

      &.is-dragging-over {
        background-color: $lightgreen;
      }

      &.is-dragging-origin {
        z-index: 10 !important;
      }
    }
  }
}
.availability-popover {
  font-size: 8pt;
}
.total-row {
  border-top: 2px solid $weekly-planner-background-colour;

  .total-card {
    padding: 0.25rem, 0.25rem;

    .available {
      color: green;
    }

    .unavailable {
      color: $red;
    }
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
