.new-budget {
  .body {
    display: flex;
    justify-content: center;

    .wizard-form-content.step-0 {
      max-width: $container-min-width;
    }
  }
}
