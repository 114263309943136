.snapshots {
  height: 600px;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;

  .layout {
    position: relative;
    overflow: visible;

    .snapshot-card {
      background: white;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      overflow: hidden;
    }
  }
}
.snapshot-container {
  height: 100%;
  width: 100%;
  .card {
    height: 100%;
    width: 100%;
  }
  .card-header {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.snapshot-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  .snapshot-icon {
    display: none;
  }
}

.snapshot-container:hover .snapshot-link {
  text-decoration: underline;
  .snapshot-icon {
    display: inline;
  }
}
