.search-bar {
  .search-pill {
    min-height: 40px;
  }
  .text-like {
    width: 175px;
  }
  .time-range {
    .form-control {
      width: 150px;
    }
    fieldset {
      border: 0;
    }
  }
  .form-input-multi-select__control {
    border: 0;
  }
  .form-input-multi-select__menu {
    width: max-content;
    z-index: 11;
  }

  .btn {
    min-width: 75px;
  }
}

.export-dialog {
  .preview {
    .card-body {
      font-size: small;
    }
  }
  .time-range {
    .form-control {
      width: 150px;
    }
    fieldset {
      border: 0;
    }
  }
  .form-input-multi-select__control {
    border: 0;
  }
  .form-input-multi-select__menu {
    width: max-content;
  }
  .form-input-multi-select__value-container {
    padding-left: 15px;
  }
}

.table {
  .selectable {
    cursor: pointer;

    &:hover {
      background-color: var(--bs-gray-100);
    }
  }

  .on-hover {
    .link {
      color: inherit;
      text-decoration: inherit;
    }

    .hover {
      opacity: 0;
    }

    &:hover {
      .hover {
        opacity: 1;
        transition: opacity 0.1s ease-in-out;
        -moz-transition: opacity 0.1s ease-in-out;
        -webkit-transition: opacity 0.1s ease-in-out;
      }

      .link {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .cell-align-left {
    text-align: start;
  }

  .cell-align-right {
    text-align: end;
  }

  .cell-align-center {
    text-align: center;
  }

  .header-align-left {
    justify-content: start;
  }

  .header-align-center {
    justify-content: center;
  }

  .header-align-right {
    justify-content: end;
  }

  tr {
    &.selectable:hover {
      td {
        background-color: var(--bs-info);
      }
    }
  }
}

.fixed-table {
  table-layout: fixed;
  width: 100%;
}

.fixed-table th,
.fixed-table td {
  overflow-wrap: break-word;
  width: auto;
}
