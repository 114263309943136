.features {
  display: flex;
  flex-flow: column;

  .header {
    background: linear-gradient(to bottom, theme-color('header-start'), theme-color('header-end'));
    height: $header-height;

    &.info {
      background: linear-gradient(to bottom, theme-color('info-start'), theme-color('info-end'));
    }

    &.warning {
      background: linear-gradient(to bottom, theme-color('warning-start'), theme-color('warning-end'));
    }

    &.support-coordination {
      background: linear-gradient(
        to bottom,
        theme-color('support-coordination-start'),
        theme-color('support-coordination-end')
      );
    }

    &.case-management {
      background: linear-gradient(to bottom, theme-color('case-management-start'), theme-color('case-management-end'));
    }
    h3 {
      font-size: medium;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .feature {
    flex: 1 1 auto;
    position: relative;
  }

  [class*='form-'] {
    font-size: inherit;
  }
  .form-input-select__menu {
    z-index: 10;
  }
}
.form-control {
  .MuiFormLabel-root {
    z-index: auto;
  }
}
