.worker-appointments {
  display: block;
  //each row is about 2X the line height, with ~.25rem padding in the y,
  max-height: calc(8 * var(--bs-body-line-height) * 2 * 1rem + 0.7rem);
  overflow: auto;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  thead,
  tbody {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  td,
  th {
    padding: 8px;
    border: 1px solid $table-border-color;
    text-align: left;
    width: 25%;
    vertical-align: middle;
  }
}
