.new-supplier-invoice {
  width: 60vw !important;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;

  .offcanvas-body {
    .top-pill {
      padding-right: 1rem;
      margin-bottom: 1rem;

      &:last-of-type {
        padding-right: 0 !important;
      }
    }
  }
}
