html,
body,
#root {
  height: 100%;
}

.app-container {
  padding: 0;
  margin: 0;
  height: 100%;

  &.desktop-view {
    min-width: $container-min-width;
  }
}

.app-row {
  height: calc(100% - $navbar-height - 10px);
  flex-wrap: nowrap;
}

.row {
  --bs-gutter-x: 0;
}

.login {
  .card-header {
    position: sticky;
    top: 0;
    background-color: theme-color('menu-background');
  }
  .card {
    width: 35rem;
  }
}

.bg-disabled {
  background-color: var(--bs-gray-500) !important;
}

.disabled {
  color: var(--bs-gray-500) !important;
}

.selectable {
  cursor: pointer;

  &:hover {
    background-color: var(--bs-gray-300);
  }
}

.system-announcement {
  border: 0.25rem solid theme-color('warning-end');
  margin: 0.5rem;
  &.overdue {
    background-color: theme-color('overdue-payment-banner');
    min-height: 300px;
    position: sticky;
  }
  flex: 0 1 auto;
}

.cascading-selector {
  display: grid;
  grid-template-columns: repeat(var(--visible-selectors, 1), 1fr);
  align-items: center;

  .cascading-selector-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .cascading-label {
    min-width: 100px;
    text-align: right;
    white-space: nowrap;
    margin-bottom: 0;
  }
  .cascading-select {
    flex-grow: 1;
    width: 100%;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  .message {
    margin-top: 1rem;
    font-size: 1.25rem;
    color: #3498db;
    text-align: center;
  }
}
.spin {
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
