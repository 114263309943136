.clickable-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.icon-round {
    border-radius: 50%;
  }

  &.icon-square {
    border-radius: 5px;
  }

  &.clickable-small {
    width: 35px;
    height: 35px;
    padding: 5px;
  }

  &.clickable-medium {
    width: 50px;
    height: 50px;
    padding: 10px;
  }

  &.clickable-large {
    width: 70px;
    height: 70px;
    padding: 15px;
  }

  & .icon-small {
    width: 15px;
    height: 15px;
  }

  & .icon-medium {
    width: 30px;
    height: 30px;
  }

  & .icon-large {
    width: 50px;
    height: 50px;
  }
  .icon-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .disabled {
    cursor: not-allowed;
  }
}
